import { Swiper } from "swiper";
import { Pagination, Grid, Navigation } from "swiper/modules";

("use strict");

// Banner right slider
const bannerRightSlider = new Swiper(".banner-right-slider", {
	modules: [Pagination, Navigation],
	slidesPerView: 1,
	spaceBetween: 20,
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
		renderBullet: function (index, className) {
			var bulletSVGs = [
				'<path id="Vector" d="M5.7035 11.1111C4.18835 11.1111 2.79946 10.6173 1.78936 9.50617C0.653 8.39506 0.147949 7.03704 0.147949 5.55556C0.147949 4.07407 0.653 2.71605 1.78936 1.60494C2.92573 0.493827 4.18835 0 5.7035 0C7.21866 0 8.48128 0.493827 9.61765 1.60494C10.754 2.71605 11.2591 3.95062 11.2591 5.55556V11.1111H5.7035Z"/>',
			];

			return (
				'<span class="' +
				className +
				'">' +
				'<svg width="11" height="11">' +
				bulletSVGs +
				"</svg>" +
				"</span>"
			);
		},
	},
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	on: {
		init: function () {
			// Play the video on the initial active slide (first slide)
			const initialSlide = this.slides[this.activeIndex];
			const video = initialSlide.querySelector('.banner-slider-video');
			if (video) video.play();
		},
		// Event triggered when the active slide changes
		slideChange: function () {
			// Pause all videos
			document.querySelectorAll('.banner-slider-video').forEach(video => {
				video.pause();
				video.currentTime = 0; // Optional: reset to beginning
			});

			// Play video on the active slide
			const activeSlide = this.slides[this.activeIndex];
			const video = activeSlide.querySelector('.banner-slider-video');
			if (video) video.play();
		}
	}
});


// Step slider
const stepSwiperSlider = () => {
	let stepTimeout;
	clearTimeout(stepTimeout);
	stepTimeout = setTimeout(() => {
		let stepInit = false;
		let stepSlider;
		if (window.innerWidth <= 991) {
			if (!stepInit) {
				stepInit = true;
				stepSlider = new Swiper(".all-steps", {
					modules: [Pagination],
					slidesPerView: 1,
					spaceBetween: 10,
					grabCursor: true,
					keyboard: true,
					autoHeight: false,
					observer: true,
					pagination: {
						el: ".swiper-pagination",
						clickable: true,
						renderBullet: function (index, className) {
							var bulletSVGs = [
								'<path id="Vector" d="M5.7035 11.1111C4.18835 11.1111 2.79946 10.6173 1.78936 9.50617C0.653 8.39506 0.147949 7.03704 0.147949 5.55556C0.147949 4.07407 0.653 2.71605 1.78936 1.60494C2.92573 0.493827 4.18835 0 5.7035 0C7.21866 0 8.48128 0.493827 9.61765 1.60494C10.754 2.71605 11.2591 3.95062 11.2591 5.55556V11.1111H5.7035Z"/>',
							];

							return (
								'<span class="' +
								className +
								'">' +
								'<svg width="11" height="11">' +
								bulletSVGs +
								"</svg>" +
								"</span>"
							);
						},
					},
					breakpoints: {
						640: {
							slidesPerView: 2,
						},
					},
				});
				stepSlider.on('slideChange', function() {
					stepSlider.pagination.render();
					stepSlider.pagination.update();
				});
			}
		} else if (stepInit) {
			stepSlider.destroy();
			stepInit = false;
		}
	}, 100); // adjust the timeout value as needed
}

stepSwiperSlider();
window.addEventListener("resize", stepSwiperSlider);


// Client logo slider
const clientLogoSwiperSlider =  () => {
	let clientTimeout;
	clearTimeout(clientTimeout);
	clientTimeout = setTimeout(() => {
		const clientSlider = new Swiper(".all-clients-logos-slider", {
			modules: [Pagination, Grid],
			slidesPerView: 2,
			grid: {
				rows: 3,
				fill: 'column',
			},
			spaceBetween: 15,
			grabCursor: true,
			keyboard: true,
			observer: true,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
				renderBullet: function (index, className) {
					var bulletSVGs = [
						'<path id="Vector" d="M5.7035 11.1111C4.18835 11.1111 2.79946 10.6173 1.78936 9.50617C0.653 8.39506 0.147949 7.03704 0.147949 5.55556C0.147949 4.07407 0.653 2.71605 1.78936 1.60494C2.92573 0.493827 4.18835 0 5.7035 0C7.21866 0 8.48128 0.493827 9.61765 1.60494C10.754 2.71605 11.2591 3.95062 11.2591 5.55556V11.1111H5.7035Z"/>',
					];

					return (
						'<span class="' +
						className +
						'">' +
						'<svg width="11" height="11">' +
						bulletSVGs +
						"</svg>" +
						"</span>"
					);
				},
			},
			breakpoints: {
				650: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				700: {
					slidesPerView: 5,
					spaceBetween: 20,
					grid: {
						rows: 2,
					},
				},
				991: {
					slidesPerView: 6,
					spaceBetween: 30,
					grid: {
						rows: 1,
						fill: 'row',
					},
				},
			},
		});
		if (clientSlider && typeof clientSlider.on === 'function') {
			clientSlider.on('slideChange', function() {
				clientSlider.pagination.render();
				clientSlider.pagination.update();
			});
		}
	}, 100);
}

clientLogoSwiperSlider();
window.addEventListener("resize", clientLogoSwiperSlider);